@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Playfair+Display:wght@600;800&display=swap');

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box {
  height: 300px;
  background-image: url(./img/wave.svg);
  background-repeat: no-repeat;
  position: sticky;
}

div.borderYtoX a:before,
div.borderYtoX a:after {
  position: absolute;
  opacity: 0.5;
  height: 100%;
  width: 2px;
  content: '';
  background: rgb(255, 255, 255);
  transition: all 0.3s;
}

div.borderYtoX a:before {
  left: 0px;
  top: 0px;
}

div.borderYtoX a:after {
  right: 0px;
  bottom: 0px;
}

div.borderYtoX a:hover:before,
div.borderYtoX a:hover:after {
  opacity: 1;
  height: 2px;
  width: 100%;
}

div.navbarcss {
  color: antiquewhite;
  font-family: Raleway;
  margin-top: 10px;
  padding: 5px;
  text-align: right;
}

div.navbarcss a {
  color: white;
  text-decoration: none;
  font: 15px Raleway;
  margin-right: 15px;
  padding: 7px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.logoname {
  color: rgb(223, 202, 202);
  text-align: left;
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
  margin-top: 12px;
  float: left;
}

.logoname span {
  color: rgb(255, 255, 255);
}

.navDiv {
  background-color: rgba(0, 0, 255, 0.699);
  height: auto;
}

.navbarcssxs {
  float: right;
  color: white;
}


.svg-div {
  background-color: blue;

}

.bannercontent {
  background-color: blue;

}

.myname-div {
  margin-top: 10px;
  height: auto;
  color: white;
}

.myname {
  text-align: center;
  padding: 15%;
}

.myname h1 {
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
}

.myname h5 {
  font-family: poppins;
}

.myimg-div {
  height: auto;
  margin-top: 10px;
  border-radius: 50%;
  
}

.myimg {
  margin-top: 40px;
  width: 300px;
  border-radius: 100%;
  position: relative;
  outline: 5px solid white;
}

.myphoneimg {
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 50%;
  border: solid 3px rgb(255, 255, 255);
  width: 70%;
  height: 70%;
  
}

.projectHead h1{
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
  font-weight: 700;
  text-align: left;
}
.projectHead{
  margin-top: -10%;
}
.HomeMainHead{
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
  font-weight: 700;
  text-align: left;
}
.AboutHead{
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
  font-weight: 700;
  text-align: center;
}
.codingskills{
  width: auto;
}
.codingskillsprogressbarcss{
 background-color: rgb(190, 190, 190);
}
.codingskillsheading{
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
}
.social{
  padding: 30px;
  display: flex;
  place-content: center;
}
.footer{
  background-color: blue;
  height: 9vh;
  color: white;
  display: flex;
  place-content: center;
  padding-top: 12px;
}
.custom-shape-divider-top-1670322343 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-top: 6%;
}

.custom-shape-divider-top-1670322343 svg {
  position: relative;
  display: block;
  width: calc(280% + 1.3px);
  height: 120px;

}

.custom-shape-divider-top-1670322343 .shape-fill {
  fill: 000;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.500);

}
.aboutbox{
  font-weight: 400;
  font-size: large;
}
.orchidbox{
  font-weight: 400;
  font-size: large;
}
.aboutline{
  background-color: black;
  height: 2px;
  box-shadow: 0px 2px 5px  rgba(0, 0, 0, 0.349);
}
.resume{
  display: flex;
  place-content: center;
  margin-top: 2%;
}
.resume img{
  width: 70%;
}
.downloadbtn{
  display: flex;
  place-content: center;
}
.orchidbox img{
  width: 100%;
}
.orchidbox{
  text-align: center;
  
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
}
.lawyerbox img{
  width: 100%;
}
.lawyerbox{
  text-align: center;
  font-size: smaller; 
  font-family: 'Libre Baskerville', serif;
  font-family: 'Marhey', cursive;
  font-family: 'Playfair Display', serif;
  font-family: 'Righteous', cursive;
}